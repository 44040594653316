import React from "react";

const SocialICons = () => {
  return (
    <div>
      <a href="" className="social-icon twitter-icon">
        <div className="social-icon-bloom"></div>
        <div className="social-icon-sparkle">
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
        </div>
        <svg
          className="social-icon-shape"
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          imageRendering="optimizeQuality"
          viewBox="0 0 512 462.799"
        >
          <path d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z" />
        </svg>
      </a>

      <a href="" className="social-icon facebook-icon">
        <div className="social-icon-bloom"></div>
        <div className="social-icon-sparkle">
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
        </div>
        <svg
          className="social-icon-shape"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
        </svg>
      </a>

      <a href="" className="social-icon instagram-icon">
        <div className="social-icon-bloom"></div>
        <div className="social-icon-sparkle">
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
        </div>
        <svg
          className="social-icon-shape"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
        </svg>
      </a>

      <a href="" className="social-icon snapchat-icon">
        <div className="social-icon-bloom"></div>
        <div className="social-icon-sparkle">
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
          <div className="social-icon-sparkle-line"></div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="social-icon-shape"
          viewBox="0 0 24 24"
        >
          <g>
            <path d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z"></path>
          </g>
        </svg>
      </a>
    </div>
  );
};

export default SocialICons;
